<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">{{$t('title.outbound')}}</template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('outboundloadsownview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>

                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="supplier-outbound-table">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(route_id)="record">
                            {{record.item.route ? record.item.route.name : ''}}
                        </template>
                        <template v-slot:cell(transport_vehicle_id)="record">
                            <span v-if="record.item.transport_vehicle">
                                <span v-if="record.item.transport_vehicle.truck_plate_number"> {{ record.item.transport_vehicle.truck_plate_number }} </span>
                                <span v-if="record.item.transport_vehicle.trailer_plate_number"> | {{ record.item.transport_vehicle.trailer_plate_number }} </span>
                            </span>
                        </template>
                        <template v-slot:cell(orders)="record">
                            {{record.item.outbound_load_orders ? record.item.outbound_load_orders.length : 0}}
                        </template>
                        <template v-slot:cell(status)="record">
                            <obl-status :status="record.item.status"></obl-status>
                        </template>
                        <template v-slot:cell(action)="record">
                            <div v-if="$global.hasRole('driver') && $global.hasPermission('outboundloadsownview')">
                                <a @click="handleInTransitClick(record.item)"
                                   v-if="record.item.status >=4 && record.item.status <= 5" :title="$t('button.title.inTransit')"
                                   v-b-tooltip.hover class="mr-1 text-success">
                                    <i class="fe fe-truck"></i>
                                </a>
                                <a @click="handleUnLoadClick(record.item)"
                                   :title="$t('button.title.unloaded')" v-b-tooltip.hover
                                   v-if="record.item.status == 6 && !record.item.is_d_unloaded"
                                   class="ml-2 text-warning">
                                    <i class="fe fe-truck"></i>
                                </a>
                                <a :title="$t('button.title.requestToProceedUnload')"
                                   v-if="record.item.is_d_unloaded"
                                    class="ml-2 text-primary">
                                    <i class="fe fe-forward" aria-hidden="true"></i>
                                </a>
                                <a @click="setOperation('detail', record.item.id)"
                                   :title="$t('button.title.detailItem')" class=" ml-1"
                                   v-b-tooltip.hover>
                                    <i class="icmn-info"></i>
                                </a>
                            </div>
                        </template>
                        <template v-slot:row-details="{ item }">
                            <b-card>
                                <b-list-group flush>
                                    <b-list-group-item></b-list-group-item>
                                </b-list-group>
                            </b-card>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left ">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]" size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.supplier-outbound-table-->
                <div class="supplier-outbound-operation">
                    <a-drawer
                        placement="right"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="handleOperationReset"
                        :visible="formFields.visible"
                        :zIndex="10"
                    >
                        <template slot="title">
                            {{ (formFields.status == 6 ? $t('title.loadInTransit') : $t('title.loadUnloaded'))}}
                            <span class="text-dark" v-if="selectedLoad">
                                <span class="badge badge-dark">
                                    <strong>{{(selectedLoad || {}).load_number}}</strong>
                                </span>
                            </span>
                        </template>
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.comment')"
                                        label-for="comment"
                                        :invalid-feedback="formErrors.first('comments')">
                                        <b-textarea
                                            id="comment"
                                            v-model="formFields.comments"
                                            type="text"
                                            :state="((formErrors.has('comments') ? false : null))"
                                            @focus="$event.target.select()"
                                        ></b-textarea>
                                    </b-form-group>
                                </b-col>
                                <div class="drawer-footer">
                                    <b-button variant="warning" @click="handleOperationReset" class="mr-2"
                                              size="sm"
                                              :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                        {{$t('button.cancel')}}
                                    </b-button>
                                    <b-button variant="success" button="submit" type="filled"
                                              :disabled="global.pendingRequests > 0" size="sm"
                                              :title="$t('button.title.updateStatus')" v-b-tooltip.hover>
                                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                                     size="12px"
                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                        {{$t('button.updateStatus')}}
                                    </b-button>
                                </div><!-- /.drawer-footer -->
                            </b-row>
                        </form>
                    </a-drawer>
                </div><!--/.supplier-outbound-operation-->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>
    </div>
</template>
<script>
    import ListingMixin from '../../../util/ListingMixin'
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from './detail'

    const FORM_STATE = {
        id: null,
        comments: null,
        status: null,
        visible: false,
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.loadNumber'),
            key: 'load_number',
            sortable: true,
        },
        {
            label: self.$t('column.route'),
            key: 'route_id',
            sortable: true,
        },
        {
            label: self.$t('column.transportVehicle'),
            key: 'transport_vehicle_id',
            sortable: true,
        },
        {
            label: self.$t('column.numberOfOrders'),
            key: 'orders',
            sortable: false,
        },
        {
            label: self.$t('column.status'),
            key: 'status',
            sortable: true,
        },
        (self.$global.hasAnyPermission(['outboundloadsownview'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right',
                key: 'action',
                width: 150,
            } : {}),
    ];

    export default {
        mixins: [ListingMixin],
        components: {
            Treeselect,
            Detail
        },
        data() {
            return {
                operationTitle: 'title.outbound',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'outbound/loads/driver',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    drivers: [],
                    transportVehicles: [],
                },
                show: true,
                selectedLoad: null,
            }
        },
        mounted() {
            this.$title = this.$t('topBar.navigations.modules.outbound')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: (this.formFields.status === 6 ? 'outbound/loads/driver/in/transit' : 'outbound/loads/driver/unloaded'),
                        method: 'post',
                        data: this.formFields,
                    })

                    this.itemUpdated()
                    this.handleOperationReset()
                    this.handleOperationClose()
                    this.loadList()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            handleInTransitClick(record) {
                this.selectedLoad = record
                this.formFields.status = 6
                this.formFields.id = record
                this.formFields.comments = null
                this.formFields.visible = true
            },
            handleUnLoadClick(record) {
                this.selectedLoad = record
                this.formFields.status = 7
                this.formFields.id = record
                this.formFields.comments = null
                this.formFields.visible = true
            },
            handleOperationReset() {
                this.formFields = {...FORM_STATE}
                this.selectedLoad = null
            },
            hasListAccess() {
                return this.$global.hasPermission('outboundloadsownview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80{
        width: 80px;
    }

    .in-center{
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    @media screen and (max-width: 768px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }
</style>
